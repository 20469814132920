import React from 'react'

export const Opened = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M0 7.33L2.83 4.5 12 13.84l9.17-9.34L24 7.33 12 19.5z" />
  </svg>
)

export const Closed = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path d="M7.33 24L4.5 21.17 13.84 12 4.5 2.83 7.33 0 19.5 12z" />
  </svg>
)

export const GitHub = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2350 2314.8" {...props}>
    <path
      d="M1175 0C525.8 0 0 525.8 0 1175c0 552.2 378.9 1010.5 890.1 1139.7-5.9-14.7-8.8-35.3-8.8-55.8v-199.8H734.4c-79.3 0-152.8-35.2-185.1-99.9-38.2-70.5-44.1-179.2-141-246.8-29.4-23.5-5.9-47 26.4-44.1 61.7 17.6 111.6 58.8 158.6 120.4 47 61.7 67.6 76.4 155.7 76.4 41.1 0 105.7-2.9 164.5-11.8 32.3-82.3 88.1-155.7 155.7-190.9-393.6-47-581.6-240.9-581.6-505.3 0-114.6 49.9-223.3 132.2-317.3-26.4-91.1-61.7-279.1 11.8-352.5 176.3 0 282 114.6 308.4 143.9 88.1-29.4 185.1-47 284.9-47 102.8 0 196.8 17.6 284.9 47 26.4-29.4 132.2-143.9 308.4-143.9 70.5 70.5 38.2 261.4 8.8 352.5 82.3 91.1 129.3 202.7 129.3 317.3 0 264.4-185.1 458.3-575.7 499.4 108.7 55.8 185.1 214.4 185.1 331.9V2256c0 8.8-2.9 17.6-2.9 26.4C2021 2123.8 2350 1689.1 2350 1175 2350 525.8 1824.2 0 1175 0z" />
  </svg>
)

export const Link = props => {
  return (
    <svg
      aria-hidden="true"
      version="1.1"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
      />
    </svg>
  )
}

export const OCaml = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      {...props}
    >
      <path d="M55.26 40.29c-9.05-.45-10.08 10.3-13.37 16.45-5.8 7.07-9.23-6.7-12.46-10.27-3.01-8.38-14.85-6.9-16.37 1.7A41.34 41.34 0 0 1 2.42 64.73C-1.53 65.66.47 69.74 0 72.6v39.09c2.32-6.25 8.58-10.57 9.58-17.56 5.75-5.56 10.85 5.68 17.67 4.9 5.1 2.02 15.59 1.6 17.62 3.42-5.3 5.1-4.38 13.66-9.66 18.82-.57 7-2.18 14.1-3.92 21.1 4.12.16 7.24-1.08 6.3-6.12.79-7.06 7.2-11.58 8.24-18.63 3.88-5.5 6.1-12.28 11.08-16.91 6.18-4.56 11.96 4.45 15.19 8.94 3.8 6.2 3.46 14.24 7.58 20.05 4.54 3.37 2.72 14.36 9.14 13.74 4.23-.14-2.28.08-3.74.11-9.97.17-20 .72-29.95.65-4.48-1.23-8.76-3.88-13.54-2.94-8.77.12-17.5 1.51-26.2 2.01L.01 139.51l.23 25.88 164.91.48.4-22.46c-12.14-1.15-24.27-2.65-36.41-3.59-10.6.67-21.26.9-31.81 1.84-1.92.48-5.26 1.39-6.35 1.62a50.5 50.5 0 0 1-5.13-20.46c-4.86-5.06-4.97-12.36-6.13-18.89.04-4.18-1.9-10.32 4.96-7.97 9.19.9 19.45-.01 26.25-7.02 6.7-4.6 6.24-13.82 11.26-19.1 5.7-2.74 13-3.02 18.05-3.82 1.25-4.73-2.88-11.92-9.7-11.85a348.86 348.86 0 0 1-19.03-2.8c-7.15-.62-11.16 6.36-11.03 12.6-.22 6.71-4.63 13.73-12.24 11.1-7.38-1.66-15.1-5.34-17.66-13.03-2.68-6.15-4.61-13-9.17-18.07-1.73-1.64-3.52-3.75-6.15-3.68Z" transform="scale(1.21035)" />
      <path fill="#484444" d="m100.36 118.5.03-.08c-.04-.19-.06-.23-.03.07z" />
      <g transform="scale(1.21035)">

        <path d="m82.92 97.9.02-.06c-.03-.15-.04-.19-.02.06z" />
      </g>
    </svg>
  )
}

export const Reason = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>

      <path
        d="M 128.128,197 H 103.702 L 91.666,174.108 H 75.618 V 197 H 54.024 v -82.718 h 37.17 c 21.948,0 34.456,10.62 34.456,29.028 0,12.508 -5.192,21.712 -14.75,26.668 z m -52.51,-65.49 v 25.37 h 15.694 c 8.732,0 13.806,-4.484 13.806,-12.862 0,-8.142 -5.074,-12.508 -13.806,-12.508 z m 63.838,-17.228 h 65.254 v 17.228 h -43.66 v 15.458 h 39.412 v 17.11 l -39.412,0.118 v 15.576 h 44.84 V 197 h -66.434 z"
        id="c" />  </svg>
  )
}

export const Rescript = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
      <g transform="translate(-80.1 -75.27) scale(1.76532)">
        <path d="M51.9 69.04c0-7.25 0-10.88 1.41-13.65a12.93 12.93 0 0 1 5.67-5.67c2.77-1.41 6.4-1.41 13.65-1.41l15.77-.92 2.37.92v84.21c0 6.04-.44 8.75-1.42 11.13a12.96 12.96 0 0 1-7.02 7.01c-2.38.99-4.96 1.3-11 1.3-6.03 0-9.05 0-11.43-1a12.96 12.96 0 0 1-7-7c-1-2.38-1-5.4-1-11.44z" />
        <circle cx="130.54" cy="69.82" r="23.26" />
      </g> </svg>
  )
}

export const FSharp = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
      <path
        d="M 1.6988832,99.824298 95.407203,6.1159794 V 52.970136 L 48.553041,99.824298 95.407203,146.67767 v 46.85417 z"
        id="path2" />
      <path
        d="M 61.939944,99.824298 95.407203,66.357039 v 66.934521 z"
        id="path4" />
      <path
        d="M 199.15493,99.824298 102.10064,6.1159794 V 52.970136 l 46.85339,46.854162 -46.85415,46.853372 v 46.85417 z"
        id="path6" />
    </svg>
  )
}